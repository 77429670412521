<template>
    <loading-animation v-if="DataLoading"></loading-animation>
    <div v-else class="m-3">
        <div class="fl-te-c mb-3">
            <h4>Invoice Details # 1</h4>
            <div class="btn-group">
                <btn size="sm" :loading="loading" loading-text="Generating.." @click="printClicked" text="Print"/>
                <btn size="sm" @click="$router.push('/sales/invoices/list/')" text="Back"/>
            </div>
        </div>
        <div class="b-1 round-2 of-h bs-4 p-0">
            <div v-if="order_info" class="p-0 pb-3">
                <h5 class="p-3 bb-1 bg-1">Order Information</h5>
                <div class="row pl-3 pt-3 pr-5">
                    <div class="col-2 label">Order ID</div>
                    <div class="col-2 p-1 b-1 text-muted round-1">{{ order_info.id }}</div>

                    <div class="col-1 label">Order Date</div>
                    <div class="col-2 p-1 b-1 text-muted round-1">{{ order_info.sale_date }}</div>
                    <div class="col-1"/>

                    <div class="col-2 label">Status</div>
                    <div class="col-2 p-1 b-1 text-muted round-1">{{ order_info.status }}</div>
                </div>
            </div>
            <div v-if="account_info" class="p-0 pb-3">
                <h5 class="p-3 bb-1 bg-1">Account Information</h5>
                <div class="row pl-3 pt-3 pr-5">
                    <div class="col-2 label">Customer ID</div>
                    <div class="col-2 p-1 b-1 text-muted round-1">{{ account_info.id }}</div>

                    <div class="col-1 label">Name</div>
                    <div class="col-2 p-1 b-1 text-muted round-1">{{ account_info.name }}</div>
                    <div class="col-1"/>

                    <div class="col-2 label">Phone Number</div>
                    <div class="col-2 p-1 b-1 text-muted round-1">{{ account_info.phone_number }}</div>
                </div>
                <div class="row pl-3 pt-3 pr-5">
                    <div class="col-2 label">E-Mail</div>
                    <div class="col-2 p-1 b-1 text-muted round-1">{{ account_info.email }}</div>
                </div>
            </div>
            <div v-if="billing_address" class="p-0 pb-3">
                <h5 class="p-3 bb-1 bg-1">Billing Address</h5>
                <div class="row pl-3 pt-3 pr-5">
                    <div class="col-2 label">Country</div>
                    <div class="col-2 p-1 b-1 text-muted round-1">{{ billing_address.country }}</div>

                    <div class="col-1 label">State</div>
                    <div class="col-2 p-1 b-1 text-muted round-1">{{ billing_address.state }}</div>
                    <div class="col-1"/>

                    <div class="col-2 label">District</div>
                    <div class="col-2 p-1 b-1 text-muted round-1">{{ billing_address.district }}</div>
                </div>
                <div class="row pl-3 pt-3 pr-5">
                    <div class="col-2 label">City</div>
                    <div class="col-2 p-1 b-1 text-muted round-1">{{ billing_address.city }}</div>

                    <div class="col-1 label">Pincode</div>
                    <div class="col-2 p-1 b-1 text-muted round-1">{{ billing_address.pincode }}</div>
                    <div class="col-1"/>
                </div>
                <div class="row pl-3 pt-3 pr-5">
                    <div class="col-2 label">Address</div>
                    <div class="col-2 p-1 b-1 text-muted round-1">{{ billing_address.address }}</div>
                </div>
            </div>
            <div v-if="shipping_address" class="p-0 pb-3">
                <h5 class="p-3 bb-1 bg-1">Shipping Address</h5>
                <div class="row pl-3 pt-3 pr-5">
                    <div class="col-2 label">Country</div>
                    <div class="col-2 p-1 b-1 text-muted round-1">{{ shipping_address.country }}</div>

                    <div class="col-1 label">State</div>
                    <div class="col-2 p-1 b-1 text-muted round-1">{{ shipping_address.state }}</div>
                    <div class="col-1"/>

                    <div class="col-2 label">District</div>
                    <div class="col-2 p-1 b-1 text-muted round-1">{{ shipping_address.district }}</div>
                </div>
                <div class="row pl-3 pt-3 pr-5">
                    <div class="col-2 label">City</div>
                    <div class="col-2 p-1 b-1 text-muted round-1">{{ shipping_address.city }}</div>

                    <div class="col-1 label">Pincode</div>
                    <div class="col-2 p-1 b-1 text-muted round-1">{{ shipping_address.pincode }}</div>
                    <div class="col-1"/>
                </div>
                <div class="row pl-3 pt-3 pr-5">
                    <div class="col-2 label">Address</div>
                    <div class="col-2 p-1 b-1 text-muted round-1">{{ shipping_address.address }}</div>
                </div>
            </div>
            <div v-if="payment" class="p-0 pb-3">
                <h5 class="p-3 bb-1 bg-1">Payment Information</h5>
                <div class="row pl-3 pt-3 pr-5">
                    <div class="col-2 label">Payment Method</div>
                    <div class="col-2 p-1 b-1 text-muted round-1">{{ payment.payment_method }}</div>

                    <div class="col-1 label">Currency</div>
                    <div class="col-2 p-1 b-1 text-muted round-1">{{ payment.payment_currency }}</div>
                    <div class="col-1"/>

                    <div class="col-2 label">Shipping Price</div>
                    <div class="col-2 p-1 b-1 text-muted round-1">{{ payment.shipping_price }}</div>
                </div>
                <div class="row pl-3 pt-3 pr-5">
                    <div class="col-2 label">Shipping Method</div>
                    <div class="col-2 p-1 b-1 text-muted round-1">{{ payment.shipping_method }}</div>
                </div>
            </div>
            <products-ordered :items="products"/>
        </div>
    </div>
</template>

<script>
import urls from '../../../../data/urls';
import axios from 'secure-axios';
import ProductsOrdered from '../Orders/Components/ProductsOrdered';

export default {
    name       : 'DetailsComponent',
    components : { ProductsOrdered },
    data () {
        return {
            DataLoading      : false,
            loading          : false,
            account_info     : '',
            billing_address  : '',
            order_info       : '',
            shipping_address : '',
            payment          : '',
            products         : '',
            id               : this.$route.params.id
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods : {
        async printClicked () {
            const that = this;
            that.loading = true;
            const response = await axios.form(urls.salesAdmin.invoices.print);
            const json = response.data;
            if (json.url) {
                window.open(json.url);
                that.$notify('Pdf Downloaded Successfully', 'Downloaded', {
                    type : 'success'
                });
                that.loading = false;
            } else {
                that.$notify('Failed to downloaded', 'Failed', {
                    type : 'danger'
                });
                that.loading = false;
            }
        },
        async loadDetails () {
            const that = this;
            that.DataLoading = true;
            const response = await axios.form(urls.salesAdmin.invoices.details,
                { invoice_id : that.id });
            const json = response.data;
            if (json.error === false) {
                that.order_info = json.order_info;
                that.account_info = json.account_info;
                that.billing_address = json.address.billing_address;
                that.shipping_address = json.address.shipping_address;
                that.payment = json.payment;
                that.products = json.products;
                that.DataLoading = false;
            } else {
                that.$notify('Details not found.. Please try again later', 'Error', {
                    type : 'danger'
                });
                that.DataLoading = false;
            }
            that.DataLoading = false;
        }
    }
};
</script>

<style scoped>
.label {
    font-weight: 500;
}
</style>
