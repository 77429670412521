<template>
    <div class="p-0 pb-3">
        <custom-simple-table class="table-borderless of-h" ref="table" title="Items"
                             :fields="fields" :data="data">
            <template #no="rowData">
                <span>{{ getSlNo(rowData.rowData, data) }}</span>
            </template>
        </custom-simple-table>
    </div>
</template>

<script>

import axios from 'secure-axios';
import urls from '../../../../../data/urls';

export default {
    props : {
        items  : { type : Array },
        status : { type : String }
    },
    name : 'ProductsOrdered',
    data () {
        return {
            data    : [],
            loading : false,
            fields  : [
                {
                    name      : '__slot:no',
                    sortField : 'no',
                    title     : 'Sl No'
                },
                {
                    name      : 'sku',
                    sortField : 'sku',
                    title     : 'SKU'
                },
                {
                    name      : 'name',
                    sortField : 'name',
                    title     : 'Item'
                },
                {
                    name      : 'unit_price',
                    sortField : 'unit_price',
                    title     : 'Price'
                },
                {
                    name      : 'quantity',
                    sortField : 'quantity',
                    title     : 'Quantity'
                },
                {
                    name      : 'sub_total',
                    sortField : 'sub_total',
                    title     : 'Sub Total'
                },
                {
                    name      : 'total_amount',
                    sortField : 'total_amount',
                    title     : 'Total'
                }
            ],
            fields1 : [
                {
                    name      : '__slot:no',
                    sortField : 'no',
                    title     : 'Sl No'
                },
                {
                    name      : 'sku',
                    sortField : 'sku',
                    title     : 'SKU'
                },
                {
                    name      : 'name',
                    sortField : 'name',
                    title     : 'Item'
                },
                {
                    name      : 'unit_price',
                    sortField : 'unit_price',
                    title     : 'Price'
                },
                {
                    name      : 'quantity',
                    sortField : 'quantity',
                    title     : 'Quantity'
                },
                {
                    name      : 'sub_total',
                    sortField : 'sub_total',
                    title     : 'Sub Total'
                },
                {
                    name      : 'total_amount',
                    sortField : 'total_amount',
                    title     : 'Total'
                },
                {
                    name  : '__slot:quantity',
                    title : 'Quantity'
                },
                {
                    name  : '__slot:reason',
                    title : 'Reason'
                }
            ]
        };
    },
    watch : {
        items : {
            handler () {
                this.setData();
            }
        }
    },
    mounted () {
        if (this.status === 'Refund') {
            this.fields = this.fields1;
        }
        this.setData();
    },
    methods : {
        getSlNo (rowData, items) {
            for (let i = 0; i <= items.length; i++) {
                if (items[i].id === rowData.id) {
                    return i + 1;
                }
            }
        },
        setData () {
            this.data = this.items;
        },
        async refundClicked () {
            const that = this;
            that.loading = true;
            const response = await axios.form(urls.salesAdmin.orders.refundOrderItem,
                { data : JSON.stringify(that.data) });
            const json = response.data;
            if (json.error === false) {
                that.data = json.items;
                that.$notify('Refund Requested', 'Success', {
                    type : 'success'
                });
                that.loading = false;
            } else {
                that.$notify('Failed to submit data.. Please try again later.', {
                    type : 'danger'
                });
                that.loading = false;
            }
        }
    }
};
</script>
